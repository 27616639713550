import { FC } from "react";
import Ad from "Components/Ad";
import { MPUWrapper, MPUInnerWrapper } from "./MPU.styled";

interface Props {
  num?: number;
  className: string;
  id?: string;
  type?: string;
}

const MPU: FC<Props> = ({ num = 1, className, type = "mpu" }) => {
  return (
    <MPUWrapper>
      <MPUInnerWrapper>
        <Ad
          className={className}
          id={`${type}${num}`}
          width="100%"
          height="200"
        />
      </MPUInnerWrapper>
    </MPUWrapper>
  );
};

export default MPU;
