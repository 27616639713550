import styled from "styled-components";
import { defaultBreakpoints } from "styled-media-query";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 300px;
  column-gap: 12px;
  padding-bottom: 31px;

  @media (max-width: ${defaultBreakpoints.medium}) {
    grid-template-columns: 1fr;
    padding-bottom: 20px;
    row-gap: 12px;
  }
`;

export const ArticlesWrapper = styled.div`
  display: grid;
  flex-direction: column;
  grid-template-columns: 1fr 1fr;
  row-gap: 12px;
  column-gap: 12px;

`;

export const MPUWrapper = styled.div`
  display: flex;
  align-items: center;
`;