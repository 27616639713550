import React, { FC } from "react";
import MPU from "common/Layout/Adverts/MPU";
import Hero from "Components/Hero";
import Item from "Components/Item";
import { ArticleComponent } from "../types";
import {getCropData} from "../getCropData";
import {ArticlesWrapper, MPUWrapper, Wrapper} from "./ArticleX6PlusMPU.styled";

type Props = {
  data: ArticleComponent;
  mpu_id?: number;
  mpu: boolean;
};


const ArticleX6MPU: FC<Props> = ({ data, mpu, mpu_id }) => {
  const articles = data.articles;

  return (
    <Wrapper>
      <ArticlesWrapper  className="articles-wrapper">
        {articles.map((article, index) => (
          <Item
            key={index}
            size={6}
            article={article}
            cropData={getCropData(article, "onebyone")}
          />
        ))}
      </ArticlesWrapper>
      <MPUWrapper>
        {mpu && mpu_id && <MPU className="six_item" num={mpu_id} />}
      </MPUWrapper>
    </Wrapper>
  );
};

export default ArticleX6MPU;