import styled from "styled-components";
import { defaultBreakpoints } from "styled-media-query";

export const MPUWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
  height: 270px;
  position: relative;
`;

export const MPUInnerWrapper = styled.div`
  position: absolute;
  top: 20px;
`;
